import { useAuthStore } from '@/store/auth'

export default defineNuxtRouteMiddleware(to => {
    const authStore = useAuthStore()

    const isAuthenticated = authStore.getAccessToken !== undefined

    if (isAuthenticated && to.path !== `/dashboard`) {
        return navigateTo(`/dashboard`)
    }
})
